<template>
    <div class="page formPage" id="exhibitionAdd">
        <el-form :model="ruleForm" :rules="rules" size="small" ref="ruleForm" label-width="100px"
                 class="demo-ruleForm">
            <!--            <div class="biaoti">{{ bianji == 1 ? '展厅编辑' : distinguish ? '展览新增' :  '展览查看'}}</div>-->
            <div class="biaoti">展览介绍</div>
            <el-row>
                <el-col :span="12">
                    <el-col :span="20">
                        <el-form-item label="展览名称" prop="exhibitionName">
                            <el-input maxlength="50"
                                      placeholder="请输入展览名称（限50字）" :disabled="!distinguish"
                                      v-model="ruleForm.exhibitionName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="票价(元)" prop="price">
                            <el-input :disabled="!distinguish"
                                      placeholder="0.00<=票价<=9999.99" v-model="ruleForm.price"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="开始日期" prop="startTime">
                            <el-date-picker
                                    :disabled="!distinguish"
                                    clearable
                                    style="width: 100%;"
                                    v-model="ruleForm.startTime"
                                    type="datetime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    placeholder="选择开始日期">
                            </el-date-picker>
                            <!--                            <el-date-picker-->
                            <!--                                    :disabled="!distinguish"-->
                            <!--                                    clearable-->
                            <!--                                    style="width: 100%;"-->
                            <!--                                    size="small"-->
                            <!--                                    v-model="dataTime"-->
                            <!--                                    type="daterange"-->
                            <!--                                    range-separator="至"-->
                            <!--                                    @change="selectDate()"-->
                            <!--                                    value-format="yyyy-MM-dd"-->
                            <!--                                    start-placeholder="开始时间"-->
                            <!--                                    end-placeholder="结束时间">-->
                            <!--                            </el-date-picker>-->
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="启用状态" prop="enabledState">
                            <el-select :disabled="!distinguish" style="width: 100%" v-model="ruleForm.enabledState"
                                       placeholder="请选择">
                                <el-option
                                        v-for="item in tyoeList"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-col>
                <el-col :span="12">
                    <el-col :span="20" :offset="4">
                        <el-form-item label="分类" prop="type">
                            <el-select :disabled="!distinguish" style="width: 100%" v-model="ruleForm.type"
                                       placeholder="请选择">
                                <el-option
                                        v-for="item in rollOverList"
                                        :key="item.id"
                                        :label="item.classificationName"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20" :offset="4">
                        <el-form-item label="地点" prop="location">
                            <el-input maxlength="50"
                                      placeholder="请输入展览地点（限50字）" :disabled="!distinguish"
                                      v-model="ruleForm.location"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20" :offset="4">
                        <el-form-item label="结束日期" prop="endTime">
                            <el-date-picker
                                    :disabled="!distinguish"
                                    clearable
                                    style="width: 100%;"
                                    v-model="ruleForm.endTime"
                                    type="datetime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    placeholder="选择结束日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20" :offset="4">
                        <el-form-item label="排序" prop="serialNumber">
                            <el-input-number style="width: 100%" v-model="ruleForm.serialNumber" :disabled="!distinguish" @blur="handleSerialNumber" :min="1" :max="999" label="请输入序号（1~999 ）序号越小显示越靠前"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="大图" prop="banner">
                        <el-button type="primary" :disabled="ruleForm.banner.length == 1 || !distinguish"
                                   size="small"
                                   @click="upDate(1)">上传图片
                        </el-button>
                        <div class="picture-format">图片尺寸：1520*500；支持格式：PNG、BMP、JPG、JPEG、GIF；单张图片限10M；限1张图片。</div>
                        <div class="flex_l_c">
                            <div class="zhanshi" v-for="item in ruleForm.banner" :key="item.imgUrl">
                                <el-image
                                        style="width: 200px;"
                                        :src="item.imgUrl"
                                        :fit="'contain'"></el-image>
                                <div @click="deletesshuj(item,1)" class="el-icon-error sheZhi"></div>
                            </div>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col>
                    <el-form-item label="小图" prop="img">
                        <el-button type="primary" :disabled="ruleForm.img.length == 1 || !distinguish"
                                   size="small"
                                   @click="upDate(2)">上传图片
                        </el-button>
                        <div class="picture-format">图片尺寸：690*400；支持格式：PNG、BMP、JPG、JPEG、GIF；单张图片限10M；限1张图片。</div>
                        <div class="flex_l_c">
                            <div class="zhanshi" v-for="item in ruleForm.img" :key="item.imgUrl">
                                <el-image
                                        style="width: 200px;"
                                        :src="item.imgUrl"
                                        :fit="'contain'"></el-image>
                                <div @click="deletesshuj(item,2)" class="el-icon-error sheZhi"></div>
                            </div>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="简介" prop="introduction">
                        <Editor :disMenus="'video'" :editortext="ruleForm.introduction" ref="editor"
                                @changeHtml="noticeGetEditor"></Editor>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="biaoti">展厅全景</div>
            <el-form-item label="链接" prop="panoramaLink">
                <el-input show-word-limit
                          maxlength="255"
                          placeholder="请输入展览全景链接（限255字）"
                          :disabled="!distinguish"
                          v-model="ruleForm.panoramaLink"></el-input>
            </el-form-item>
            <el-row>
                <el-col>
                    <el-form-item label="图片" prop="panorama">
                        <el-button type="primary" :disabled="ruleForm.panorama.length == 1 || !distinguish"
                                   size="small"
                                   @click="upDate(3)">上传图片
                        </el-button>
                        <div class="picture-format">图片尺寸：690*400；支持格式：PNG、BMP、JPG、JPEG、GIF；单张图片限10M；限1张图片。</div>
                        <div class="flex_l_c">
                            <div class="zhanshi" v-for="item in ruleForm.panorama" :key="item.imgUrl">
                                <el-image
                                        style="width: 200px;"
                                        :src="item.imgUrl"
                                        :fit="'contain'"></el-image>
                                <div @click="deletesshuj(item,3)" class="el-icon-error sheZhi"></div>
                            </div>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>


            <div style="text-align: center">
                <el-button size="small"
                           v-if="distinguish && bianji == 1"
                           type="primary" @click="submitForm('ruleForm',0)">
                    保存
                </el-button>
                <el-button :loading="tijiao" size="small"
                           v-if="distinguish && bianji != 1"
                           type="primary" @click="submitForm('ruleForm',1)">
                    提交
                </el-button>
                <el-button size="small" @click="returnSuperior()">
                    取消
                </el-button>
            </div>
        </el-form>
        <uploadPopup ref="uploadpopup" :fileList="fileList" :allowFileNumber="allowFileNumber"
                     :allowFileSize="allowFileSize" :allowFiles="allowFiles" @updatalist=receiveList></uploadPopup>
    </div>
</template>

<script>
    import Editor from '@/components/editor/editor'; // 导入富文本组件
    import uploadPopup from '../exhibition/uploadPopup'

    export default {
        name: "exhibitionAdd",
        components: {Editor, uploadPopup},
        data() {
            //价格规则验证
            let validatePass2 = (rule, value, callback) => {
                console.log(value)
                if (value < 0) {
                    callback(new Error('票价必须大于0'));
                } else if (value > 9999.99) {
                    callback(new Error('票价必须大于小于9999.99'));
                } else {
                    callback();
                }
            };

            return {
                ruleForm: {
                    exhibitionName: '', //展览名称
                    type: '',//分类
                    price: '',//票价
                    location: '',//地点
                    startTime: '',//开始时间
                    endTime: '',//结束时间
                    enabledState: '',//启用状态
                    serialNumber:1,
                    banner: [],//banner
                    img: [],//图片
                    introduction: '',//简介
                    panoramaLink: '',//链接
                    panorama: [],//全景图片
                    guidedExhibitionFiles: [],
                },
                rules: {
                    exhibitionName: [
                        {required: true, message: '请输入展览名称', trigger: 'blur'},
                    ],
                    price: [
                        {validator: validatePass2, trigger: 'blur'},
                        {
                            pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: '请输入正确的价格'
                        },
                    ],
                    banner: [
                        {required: true, message: '请至少上传一张图片', trigger: 'change'},
                    ],
                    img: [
                        {required: true, message: '请至少上传一张图片', trigger: 'change'},
                    ],
                    enabledState: [
                        {required: true, message: '请选择启用状态', trigger: 'change'},
                    ],
                    startTime: [
                        {required: true, message: '请选择开始时间', trigger: 'change'},
                    ],
                    type:[
                        {required: true, message: '请选择分类', trigger: 'change'},
                    ],
                    serialNumber:[
                        {required: true, message: '请填写排序', trigger: 'change'},
                    ],
                },

                tijiao: false,
                dataTime: '',//日期时段
                rollOverList: [],
                tyoeList: [
                    {
                        label: '启用',
                        id: '1',
                    },
                    {
                        label: '禁用',
                        id: '0',
                    },
                ],
                allowFiles: [],//上传文件类型
                allowFileSize: 10,//单个最大 MB
                allowFileNumber: 10,//上传数量
                fileList: [],//上传历史
                upDateNumber: '',//哪个上传
                distinguish: true, //否是查看
                bianji: 0, //是否编辑,1为编辑
            }
        },
        mounted() {
            if (this.$route.query.id) {
                this.init()
            }
            console.log(this.$route.query.num)
            if (this.$route.query.num == 1) {
                this.bianji = this.$route.query.num
            } else if (this.$route.query.num == 0) {
                this.distinguish = false
            }
            this.getClassification()
        },
        methods: {
            //查看、编辑时获取信息
            init() {
                if (this.$route.query.id && this.$route.query.id != 0) {
                    this.$axios(this.api.zhanLanMessage.zhanlanQueryById + this.$route.query.id, {}, 'get').then(res => {
                        if (res.status) {
                            this.ruleForm = res.data
                            this.ruleForm.type = res.data.typeName;
                            this.dataTime = [this.ruleForm.startTime, this.ruleForm.endTime]
                            console.log(this.dataTime)
                        }
                    })
                }
            },

            //获取分类
            getClassification() {
                this.$axios(this.api.zhanLanMessage.fenLeiQueryAll, {}, 'get').then(res => {
                    if (res.status) {
                        this.rollOverList = res.data
                    }
                })
            },

            selectDate() {
                if (!this.dataTime) {
                    this.ruleForm.startTime = '';
                    this.ruleForm.endTime = '';
                } else {
                    this.ruleForm.startTime = this.dataTime[0];
                    this.ruleForm.endTime = this.dataTime[1];
                }
            },

            // 获取富文本数据(报名须知)
            noticeGetEditor(data) {
                if (data === '<p><br></p>') {
                    data = '';
                }
                this.ruleForm.introduction = data;
            },
            //接受上传数据
            receiveList(arry) {
                console.log(arry)
                if (this.upDateNumber == 1) {
                    arry[0].forEach(item => {
                        item.fileType = 2
                        item.type = 1
                    })
                    this.ruleForm.banner = arry[0];
                } else if (this.upDateNumber == 2) {
                    arry[0].forEach(item => {
                        item.fileType = 1
                        item.type = 1
                    })
                    this.ruleForm.img = arry[0];
                } else {
                    arry[0].forEach(item => {
                        item.fileType = 3
                        item.type = 1
                    })
                    this.ruleForm.panorama = arry[0];
                }
            },

            //上传 num1为banner上传 2图片上传,//3全景图片
            upDate(num) {
                this.upDateNumber = num
                this.$refs.uploadpopup.uploadPath = 'guided';
                if (num == 1) {
                    this.$refs.uploadpopup.resolvingPower = '1520*500';
                    this.allowFiles = ['png', 'bmp', 'jpg', 'jpeg', 'gif'];
                    this.fileList = this.ruleForm.banner;
                    this.allowFileSize = 10;
                    this.allowFileNumber = 1;
                } else if (num == 2) {
                    this.$refs.uploadpopup.resolvingPower = '690*400';
                    this.allowFiles = ['png', 'bmp', 'jpg', 'jpeg', 'gif'];
                    this.fileList = this.ruleForm.img;
                    this.allowFileSize = 10;
                    this.allowFileNumber = 1;
                } else {
                    this.$refs.uploadpopup.resolvingPower = '690*400';
                    this.allowFiles = ['png', 'bmp', 'jpg', 'jpeg', 'gif'];
                    this.fileList = this.ruleForm.panorama;
                    this.allowFileSize = 10;
                    this.allowFileNumber = 1;
                }
                this.$refs.uploadpopup.numb = 1;
                this.$refs.uploadpopup.dialogVisible = true;
                this.$refs.uploadpopup.uploading = true;
            },
            //设为主显 num 1为banner 2为图片
            mainDisplay(address, num) {
                if (num == 1) {
                    this.ruleForm.banner.forEach(item => {
                        if (item.imgUrl === address) {
                            item.fileShow = 1;
                        } else {
                            item.fileShow = 0;
                        }
                    })
                } else {
                    this.ruleForm.img.forEach(item => {
                        if (item.imgUrl == address) {
                            item.fileShow = 1;
                        } else {
                            item.fileShow = 0;
                        }
                    })
                }
                console.log(this.ruleForm.img)
            },

            //删除
            deletesshuj(row, num) {
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    if (num == 1) {
                        this.ruleForm.banner = [];
                    } else if (num == 2) {
                        this.ruleForm.img = [];
                    } else {
                        this.ruleForm.panorama = [];
                    }
                })
            },

            //两个日期比大小
            dateCompare(startTime, endTime) {
                //时间比较（yyyy-MM-dd HH:mm:ss）
                if (endTime == '' || endTime == null){
                    return false
                }
                let startTimes = startTime.substring(0, 10).split('-');
                let endTimes = endTime.substring(0, 10).split('-');
                startTime = startTimes[1] + '-' + startTimes[2] + '-' + startTimes[0] + ' ' + startTime.substring(10, 19);
                endTime = endTimes[1] + '-' + endTimes[2] + '-' + endTimes[0] + ' ' + endTime.substring(10, 19);
                let thisResult = (Date.parse(endTime) - Date.parse(startTime)) / 3600 / 1000;
                if (thisResult < 0) {
                    return  true
                } else if (thisResult > 0) {
                    return  false
                } else if (thisResult == 0) {
                    return  true
                } else {
                    return false;
                }

            },

            //num 0保存，1新增
            submitForm(formName, num) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.ruleForm.exhibitionName.trim() == '') {
                            this.$message.error('请输入展览名称')
                            return
                        }
                        if (this.dateCompare(this.ruleForm.startTime, this.ruleForm.endTime)) {
                            this.$message.error('开始日期必须大于结束日期')
                            return
                        }
                        this.tijiao = true
                        // 防抖
                        this.ruleForm.guidedExhibitionFiles = [...this.ruleForm.img, ...this.ruleForm.banner, ...this.ruleForm.panorama]
                        let list = JSON.parse(JSON.stringify(this.ruleForm))
                        this.$delete(list, 'img')
                        this.$delete(list, 'banner')
                        this.$delete(list, 'panorama')
                        if (num == 0) { //保存
                            this.$axios(this.api.zhanLanMessage.zhanLanUpdateById, {...list}, 'put').then(res => {
                                if (res.status) {
                                    this.$message.success('修改成功')
                                    this.returnSuperior();
                                } else {
                                    this.$message.error(res.msg);
                                }
                                this.tijiao = false
                            })
                        } else { //新增
                            this.$axios(this.api.zhanLanMessage.saveExhibitionManagement, {...list}, 'post').then(res => {
                                console.log(res);
                                if (res.status) {
                                    this.$message.success('新增成功')
                                    this.returnSuperior();
                                } else {
                                    this.$message.error(res.msg);
                                }
                                this.tijiao = false
                            })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },

            //返回展厅列表
            returnSuperior() {
                this.$router.push({
                    path: '/exhibitionManagement/management/exhibitionList',
                })
            },

            //排序改变
            handleSerialNumber(price){
                if (!this.ruleForm.serialNumber){
                    this.ruleForm.serialNumber = 1;
                }
            },

        },
    }
</script>

<style scoped>
    .picture-format {
        font-size: 12px;
    }

    .biaoti {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .zhanshi {
        position: relative;
    }

    .sheZhi {
        position: absolute;
        font-size: 18px;
        color: #7F7F7F;
        transform: translateX(-50%) translateY(-50%);
        cursor: pointer;
    }
</style>